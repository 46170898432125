import { defineStore } from 'pinia';
import type {
  User,
  Account,
  AccountNumber,
  Directory,
  Settings,
  Teams,
  TeamsNumbers,
  SettingResponseData,
  SipHost,
  UserStatusEnum,
  InviteUserApiResponse,
  EmptyObject,
  VoiceMail,
  Group,
} from '@/types';
import { camelCase } from 'lodash';
import { useIndexesStore, useWebrtcStore } from '@/stores';
import collect from 'collect.js';
import { computed, ref, watch } from 'vue';
import { useImportedContacts } from '@/stores/user/imported-contacts';
import { useArray } from '@/composables';

export const useUserStore = defineStore(
  'user',
  () => {
    const webrtcStore = useWebrtcStore();

    const user = ref<User>({
      userId: 0,
      userName: '',
      authToken: '',
      refreshToken: '',
      email: '',
      ipAddress: '',
      phoneNumber: '',
      createdBy: '',
      createdAt: '',
      updatedAt: '',
      deletedAt: '',
    });

    const currentAccount = ref<Account | EmptyObject>({});

    const settings = ref<Settings>({
      firstLogin: true,
      bulkInvite: true,
      fullName: '',
      userType: '',
      jobTitle: '',
      company: '',
      birthday: '',
      emailType: 'work',
      email: '',
      addressType: 'work',
      address1: '',
      address2: '',
      city: '',
      state: '',
    });

    const directories = ref<Directory[]>([]);
    const groups = ref<Group[]>([]);
    const publicStorage = ref('');
    const myNumbers = ref<AccountNumber[]>([]);
    const teams = ref<Teams[]>([]);
    const teamsNumbers = ref<TeamsNumbers[]>([]);
    const currentLevel = ref('');
    const onlineStatus = ref<UserStatusEnum>('online');
    const sipHost = ref<SipHost | EmptyObject>({});
    const supportLink = ref('');
    const voicemails = ref<VoiceMail[]>([]);

    const isFirstLogin = computed(() => {
      if (user.value.userId === 0) {
        return false;
      }

      return settings.value.firstLogin ?? true;
    });

    const directory = computed(() => {
      return (userId: string) =>
        directories.value.find(
          (directory) => directory.userId.toString() === userId,
        );
    });

    const favoriteDirectory = computed(() =>
      directories.value.filter((directory) => directory.favorite),
    );

    const favoriteGroup = computed(() =>
      groups.value.filter((group) => group.favorite),
    );

    const directoriesSorted = computed(() =>
      collect<Directory>(directories.value).sortBy('userName').all(),
    );

    const groupById = computed(() => {
      return (groupId: number) =>
        groups.value.find((group) => group.id === groupId);
    });

    const groupUsersById = computed(() => {
      return (groupId: number) => {
        const result = groups.value.find((group) => group.id === groupId);

        if (!result) return [];

        return result.users.map((user) => user.id);
      };
    });

    const getGroupsId = computed(() => {
      return groups.value.map((group) => group.id);
    });

    function setUser(newUser: User) {
      user.value = newUser;
    }

    function setUserName(userName: string) {
      user.value = {
        ...user.value,
        userName,
      };
    }

    function setUserEmail(userEmail: string) {
      user.value = {
        ...user.value,
        email: userEmail,
      };
    }

    function setToken(token: string, refreshToken: string) {
      user.value = {
        ...user.value,
        authToken: token,
        refreshToken,
      };
    }

    function updateNameSettings(fullName: string) {
      settings.value = {
        ...settings.value,
        fullName,
      };
    }

    function setCurrentAccount(newCurrentAccount: Account) {
      currentAccount.value = newCurrentAccount;
    }
    function setSettings(newSettings: SettingResponseData[]) {
      for (const setting of newSettings) {
        const key = camelCase(setting.pref_name) as keyof Settings;
        if (key === 'firstLogin' || key === 'bulkInvite') {
          settings.value[key] = !!parseInt(setting.pref_value);
        } else {
          settings.value[key] = setting.pref_value as never;
        }
      }
    }
    function setDirectory(directory: Directory) {
      directories.value.push(directory);
    }

    function setPublicStorage(storage: string) {
      publicStorage.value = storage;
    }

    function findIndexDirectories(userId: string) {
      return directories.value.findIndex(
        (directory) => directory.userId.toString() === userId,
      );
    }

    function findIndexGroups(id: number) {
      return groups.value.findIndex((group) => group.id === id);
    }

    function setFavorite(userId: string) {
      const index = findIndexDirectories(userId);

      if (index !== -1) {
        directories.value[index].favorite = true;
      }
    }

    function setGroupFavorite(id: number) {
      const index = findIndexGroups(id);

      if (index !== -1) {
        groups.value[index].favorite = true;
      }
    }

    async function addDirectory(data: InviteUserApiResponse) {
      const indexStore = useIndexesStore();

      const path = `avatar/${data.user.user_id}`;

      const directoryInfo: Directory = {
        userId: data.user.user_id,
        userType: data.user_type,
        userName: data.user.user_name,
        accountCode: currentAccount.value.accountCode,
        image: {
          20: `${path}_20x20.jpg`,
          32: `${path}_32x32.jpg`,
          80: `${path}_80x80.jpg`,
        },
      };

      if (data.settings && Object.keys(data.settings).length > 0) {
        directoryInfo.settings = {
          fullName: data.settings.full_name,
          userType: data.settings.user_type,
          jobTitle: data.settings.job_title,
          company: data.settings.company,
          birthday: data.settings.birthday,
          emailType: data.settings.email_type,
          email: data.settings.email,
          addressType: data.settings.address_type,
          address1: data.settings.address1,
          address2: data.settings.address2,
          city: data.settings.city,
          state: data.settings.state,
        };
      }
      const directoryTmp = directories.value;
      directoryTmp.push(directoryInfo);
      directories.value = [...directoryTmp];
      indexStore.setDirectoryIndex(directoryInfo);
      indexStore.setAvatarImage(directoryInfo.userId);
    }

    function updateDirectory(directory: Directory) {
      const index = findIndexDirectories(directory.userId.toString());

      if (index !== -1) {
        directories.value[index] = directory;
      }
    }

    function removeDirectory(userId: string) {
      const index = findIndexDirectories(userId);

      if (index !== -1) {
        directories.value.splice(index, 1);
      }
    }

    function removeFavorite(userId: string) {
      const index = findIndexDirectories(userId);

      if (index !== -1) {
        directories.value[index].favorite = false;
      }
    }

    function removeFavoriteGroup(id: number) {
      const index = findIndexGroups(id);

      if (index !== -1) {
        groups.value[index].favorite = false;
      }
    }

    function setCurrentLevel(level: string) {
      currentLevel.value = level;
    }

    function setOnlineStatus(status: UserStatusEnum) {
      onlineStatus.value = status;
    }

    function setSip(sip: SipHost) {
      sipHost.value = sip;
    }

    function setMyNumbers(number: AccountNumber) {
      myNumbers.value.push(number);

      myNumbers.value.sort((a, b) => {
        if (!a.name || !b.name) return +!a.name - +!b.name;
        if (!a.name && !b.name) return 0;
        return a.name.localeCompare(b.name);
      });
    }

    function removeMyNumbers() {
      myNumbers.value = [];
    }

    function findIndexMyNumberByNumber(number: string) {
      return webrtcStore.userAgentArray.findIndex(
        (element) => element.number === number,
      );
    }

    function setActiveMyNumber() {
      if (webrtcStore.userAgentArray.length === 0) {
        for (const row of myNumbers.value) row.registered = false;
        return;
      }

      if (myNumbers.value.length > 0) {
        for (const row of myNumbers.value) {
          const index = findIndexMyNumberByNumber(row.numbersUnmasked);
          if (index !== -1) row.registered = true;
        }
      }
    }

    function setTeams(team: Teams) {
      teams.value.push(team);
    }

    function setTeamsNumbers(teamNumber: TeamsNumbers) {
      teamsNumbers.value.push(teamNumber);
    }

    function findIndexTeamByUserId(userId: string) {
      return teams.value.findIndex((team) => team.userId === userId);
    }

    function findTeamByNumber(number: string) {
      return teams.value.find((team) =>
        team.assignedNumbers.some((n) => n.number === number),
      );
    }

    function findIndexMyNumbersByNumber(number: string) {
      return myNumbers.value.findIndex(
        (element) => element.numbersUnmasked === number,
      );
    }

    function setSupportLink(link: string) {
      supportLink.value = link;
    }

    function setVoicemails(voicemail: VoiceMail) {
      voicemails.value.push(voicemail);
    }

    function getNameByUserId(id: number) {
      if (id === user.value.userId) {
        return user.value.userName;
      }
      return directory.value(id.toString())?.userName ?? '';
    }

    function sortGroup() {
      groups.value = groups.value.map((group) => {
        group.users.sort((a, b) => {
          return a.name ? (b.name ? a.name.localeCompare(b.name) : -1) : 1;
        });
        return group;
      });

      groups.value.sort((a, b) => {
        const userA =
          a.users[0].id === user.value.userId
            ? a.users[1].name
            : a.users[0].name;
        const userB =
          b.users[0].id === user.value.userId
            ? b.users[1].name
            : b.users[0].name;

        const nameA = a.name || (a.users && userA);
        const nameB = b.name || (b.users && userB);

        return nameA.localeCompare(nameB);
      });
    }

    function setGroup(group: Group) {
      groups.value.push(group);
      sortGroup();
    }

    function setGroupName(groupId: string, name?: string) {
      const { findIndexByValue } = useArray(groups.value);
      const index = findIndexByValue('id', parseInt(groupId, 10));

      if (index === -1) return;

      groups.value[index].name = name;
      sortGroup();
    }

    watch(
      () => webrtcStore.userAgentArray,
      () => {
        setActiveMyNumber();
      },
      { deep: true },
    );

    const {
      importedContacts,
      countImportedContactsByProvider,
      emailsProviderSynced,
      getImportedContactsById,
      setImportedContacts,
      setEmailProviderAccount,
      getEmailProviderAccount,
      removeImportedContactsByProvider,
      setSourceEmailProvider,
      sortImportedContactsByNameAsc,
      setImportedContactsFavorite,
      setImportedDirectoryContacts,
      favoriteImportedContacts,
      updateContact,
      deleteContact,
      findImportedContactByNumber,
    } = useImportedContacts();

    return {
      user,
      currentAccount,
      settings,
      directories,
      publicStorage,
      myNumbers,
      teams,
      teamsNumbers,
      currentLevel,
      onlineStatus,
      sipHost,
      isFirstLogin,
      directory,
      favoriteDirectory,
      favoriteGroup,
      directoriesSorted,
      groupById,
      groupUsersById,
      supportLink,
      voicemails,
      groups,
      getGroupsId,
      importedContacts,
      countImportedContactsByProvider,
      emailsProviderSynced,
      getImportedContactsById,
      setUser,
      setUserName,
      setUserEmail,
      setToken,
      setCurrentAccount,
      setSettings,
      setDirectory,
      setPublicStorage,
      updateNameSettings,
      setFavorite,
      setGroupFavorite,
      addDirectory,
      updateDirectory,
      removeDirectory,
      removeFavorite,
      removeFavoriteGroup,
      setCurrentLevel,
      setOnlineStatus,
      setSip,
      setMyNumbers,
      removeMyNumbers,
      setTeams,
      setTeamsNumbers,
      findIndexTeamByUserId,
      findIndexMyNumbersByNumber,
      setSupportLink,
      setVoicemails,
      getNameByUserId,
      setGroup,
      setGroupName,
      setImportedContacts,
      setEmailProviderAccount,
      getEmailProviderAccount,
      removeImportedContactsByProvider,
      setSourceEmailProvider,
      sortImportedContactsByNameAsc,
      setImportedContactsFavorite,
      setImportedDirectoryContacts,
      favoriteImportedContacts,
      updateContact,
      deleteContact,
      findImportedContactByNumber,
      findTeamByNumber,
    };
  },
  { persist: true },
);
